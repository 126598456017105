import React from 'react';
import {Modal, Button, Form} from 'react-bootstrap';

const DynamicModalDetails = ({show, handleClose, entity = {}, title, fields}) => {
    const renderField = (field) => {
        switch (field.type) {
            case 'attachments':
                const attachments = entity[field.name] || [];

                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}:</Form.Label>
                        <div className="form-control-plaintext">
                            {attachments.length > 0 ? (
                                <ul>
                                    {attachments.map((attachment, index) => (
                                        <li key={index}>
                                            <strong>{attachment.filename}</strong>
                                            <span> ({attachment.contentType})</span> -
                                            <a href={attachment.url} target="_blank"
                                               rel="noopener noreferrer"> View/Download </a>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div>No attachments available</div>
                            )}
                        </div>
                    </Form.Group>
                );
            case 'email':
            case 'emailWithClients':
                const emails = entity[field.name];

                console.log(emails)

                // Ensure emails is an array; if it's not, handle accordingly
                const emailList = Array.isArray(emails) ? emails : [emails];

                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}:</Form.Label>
                        <div className="form-control-plaintext">
                            {emailList.length > 0 && emailList[0] !== '' ? emailList.join(', ') : 'N/A'}
                        </div>
                    </Form.Group>
                );
            default:
                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}:</Form.Label>
                        <div className="form-control-plaintext">
                            {entity[field.name] || 'N/A'}
                        </div>
                    </Form.Group>
                );
        }
        ;
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {fields.map(renderField)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DynamicModalDetails;
